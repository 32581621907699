import React, { useEffect, useContext, useState, useRef } from 'react';
import Slider from '../components/Slider/Slider';
import './gallery.scss';
import { AppContext } from '../AppContext';
import { LocaleContext } from '../components/Layout';
import Div100vh from 'react-div-100vh';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import calculateDimensions from '../utils/calculateDimensions';
import debounce from '../utils/debounce';
import useKey from '../hooks/useKey';
import DragHint from '../components/DragHint/DragHint';

// Loads intersection observer polyfill if needed
async function loadPolyfills() {
  if (typeof window === 'undefined') return;
  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }
}
loadPolyfills();

export default ({ data, location, entry }) => {
  const [sizes, setSizes] = useState({});
  const [deltaX, setDeltaX] = useState(0);
  const [deltaY, setDeltaY] = useState(0);

  const { setTotalSlides, setSlide, setInfo, setTheme } = useContext(
    AppContext
  );
  const { locale } = useContext(LocaleContext);

  const images = data.prismicGallery.data.body[0].items;
  const description =
    locale === 'en'
      ? data.prismicGallery.data.description.html
      : data.prismicGallery.data.description_es.html;
  const title =
    locale === 'en'
      ? data.prismicGallery.data.title.text
      : data.prismicGallery.data.title_es.text;
  const seo_title =
    locale === 'en'
      ? data.prismicGallery.data.seo_title.text
      : data.prismicGallery.data.seo_title_es.text;
  const seo_description =
    locale === 'en'
      ? data.prismicGallery.data.seo_description
      : data.prismicGallery.data.seo_description_es;

  const galleryRef = useRef();

  useEffect(() => {
    setTheme('dark');
    setSizes(
      calculateDimensions(
        images[0].image.localFile.childImageSharp.fixed.aspectRatio
      )
    );
    const debouncedHandleResize = debounce(() => {
      setSizes(
        calculateDimensions(
          images[0].image.localFile.childImageSharp.fixed.aspectRatio
        )
      );
    }, 250);

    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []);

  useEffect(() => {
    setDeltaX(Math.abs(sizes.windowWidth - sizes.containerWidth));
    setDeltaY(Math.abs(sizes.windowHeight - sizes.containerHeight));
  }, [sizes]);

  // Keyboard stuff
  const rightArrow = useKey('ArrowRight');
  const leftArrow = useKey('ArrowLeft');

  const items = [];

  // Preview - if we land directly in the gallery, put a gatsby image, otherwise the preloaded one
  typeof entry.state.from === 'undefined'
    ? items.push({
        img: images[0].image.localFile.childImageSharp.fixed,
        alt: images[0].image.alt,
      })
    : items.push({
        img: images[0].image.url,
        alt: images[0].image.alt,
      });

  if (description) {
    // Title and description
    items.push({
      title: title,
      desc: description,
    });
  }

  // Then push the rest of the images
  for (let i = 1; i < images.length; i++) {
    // Sometimes, probably if images are moved/deleted, localFile could be null
    if (images[i].image.localFile !== null)
      items.push({
        img: images[i].image.localFile.childImageSharp.fixed,
        alt: images[i].image.alt,
      });
  }

  setTotalSlides(items.length);

  useEffect(() => {
    setSlide(1);
    setInfo(items[0].alt);
  }, []);

  return (
    <>
      <SEO
        title={seo_title ? seo_title : title}
        description={seo_description}
        lang={locale}
        // First image of the gallery as og:image
        image={images[0].image.url}
      />
      <DragHint />
      <Div100vh>
        <div className="gallery-container" ref={galleryRef}>
          <Slider
            items={items}
            size={sizes}
            deltaX={deltaX}
            deltaY={deltaY}
            galleryName={
              locale === 'en'
                ? location.pathname.split('/')[2]
                : location.pathname.split('/')[3]
            }
            sectionName={
              locale === 'en'
                ? location.pathname.split('/')[1]
                : location.pathname.split('/')[2]
            }
            comingFrom={entry.state.from}
            rightArrow={rightArrow}
            leftArrow={leftArrow}
            ref={galleryRef}
          ></Slider>
        </div>
      </Div100vh>
    </>
  );
};

export const GalleryQuery = graphql`
  query GetGallery($id: String) {
    prismicGallery(id: { eq: $id }) {
      data {
        seo_title {
          text
        }
        seo_title_es {
          text
        }
        title {
          text
        }
        title_es {
          text
        }
        description {
          html
        }
        description_es {
          html
        }
        body {
          items {
            image {
              url
              alt
              localFile {
                childImageSharp {
                  fixed(width: 1920) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    aspectRatio
                  }
                }
              }
            }
          }
        }
        seo_description
        seo_description_es
      }
    }
  }
`;
