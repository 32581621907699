import useIphoneDetection from '../hooks/useIphoneDetection';
import { isPlainObject } from 'lodash-es';

// Make sure this is always synced with gallery.scss > .slide & Nav.scss > .nav/.indicator
export const PADDING = {
  TOP: 70,
  BOTTOM: 20,
  LEFT: 20,
  RIGHT: 20,
};

const calculateDimensions = ratio => {
  const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 1000;
  const windowHeight =
    typeof window !== 'undefined' ? window.innerHeight : 1000;

  const isMobileLandscape =
    typeof window !== 'undefined'
      ? window.matchMedia('(max-width: 768px)').matches ||
        (window.matchMedia('(max-device-width: 896px)').matches &&
          window.matchMedia('(orientation:landscape)').matches)
      : false;

  let finalWidth = windowWidth - PADDING.LEFT - PADDING.RIGHT;
  let finalHeight = isMobileLandscape
    ? windowHeight
    : windowHeight - PADDING.TOP - PADDING.BOTTOM;

  // If the ratio is not respected
  if (finalWidth / finalHeight > ratio) {
    finalWidth = finalHeight * ratio;
  } else if (finalWidth / finalHeight < ratio) {
    finalHeight = finalWidth / ratio;
  }

  return {
    containerWidth: finalWidth,
    containerHeight: finalHeight,
    windowWidth: windowWidth,
    windowHeight: windowHeight,
  };
};

export default calculateDimensions;
