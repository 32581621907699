import React, {
  useRef,
  useContext,
  useEffect,
  useState,
  forwardRef,
  Fragment,
} from 'react';
import clamp from 'lodash-es/clamp';
import debounce from '../../utils/debounce';
import { useSprings, animated, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import Scrollbar from 'react-scrollbars-custom';
import { useTriggerTransition } from '@mjso/gatsby-plugin-transition-link';
import Img from 'gatsby-image';
import useMediaQuery from '../../hooks/useMediaQuery';
import useScreenSize from '../../hooks/useScreenSize';
import { parsePath } from 'gatsby-link';

import 'balloon-css';

import { AppContext } from '../../AppContext';
import { LocaleContext } from '../Layout';
import Arrow from '../Atoms/Arrow';
import { PADDING } from '../../utils/calculateDimensions';

// eslint-disable-next-line react/display-name
const Slider = forwardRef(
  (
    {
      items,
      size,
      galleryName,
      deltaX,
      deltaY,
      comingFrom,
      sectionName,
      rightArrow,
      leftArrow,
    },
    ref
  ) => {
    const [removeBottomPadding, setRemoveBottomPadding] = useState(false);
    const [isMobileLandscape, setIsMobileLandscape] = useState(false);
    const windowSize = useScreenSize();
    const isLandscape = useMediaQuery('(orientation:landscape)');

    const gatsbyLoader =
      typeof window === 'undefined'
        ? {
            hovering: () => {},
            prefetch: () => {},
          }
        : window.___loader;

    const { setTotalSlides, setSlide, setInfo, setTheme, slide } = useContext(
      AppContext
    );

    const { locale } = useContext(LocaleContext);

    const triggerTransition = useTriggerTransition();

    const parentPath = `/${
      typeof comingFrom !== 'undefined'
        ? comingFrom
        : locale === 'en'
        ? `${sectionName}`
        : `${locale}/${sectionName}`
    }`;
    gatsbyLoader.prefetch(parsePath(parentPath).pathname);

    const onDragXRest = ({ mountGalleryRoute }) => {
      if (mountGalleryRoute) {
        triggerTransition({
          to: parentPath,
          exit: {
            length: 0.1,
          },
          entry: {
            length: 0,
            state: { active: galleryName },
          },
        });
      }
    };

    useEffect(() => {
      if (items.some(item => item.desc)) {
        // if we have the slide with the description, set the padding bottom to be 0
        setRemoveBottomPadding(true);
      }

      return () => setTotalSlides(0);
    }, []);

    useEffect(() => {
      setIsMobileLandscape(windowSize <= 896 && isLandscape);
    }, [windowSize, isLandscape]);

    // Current slide
    const index = useRef(0);

    // Holds the active gallery index where the drag movement started
    const startingIndex = useRef(0);

    // This value holds 'y' or 'x' and tells us if the user is trying
    // to drag horizontally or vertically
    const axis = useRef(null);

    const [{ w, h }, setSize] = useSpring(() => ({
      w: 0,
      h: 0,
      mountGalleryRoute: 0,
      onRest: onDragXRest,
      config: {
        clamp: true,
        precision: 1,
      },
    }));

    const [props, set] = useSprings(items.length, i => ({
      slideX: i * windowSize,
      display: 'flex',
      config: {
        clamp: true,
        tension: 120,
      },
    }));

    const bind = useDrag(
      ({
        down,
        movement: [mx],
        direction: [xDir, yDir],
        distance,
        cancel,
        canceled,
        first,
        last,
      }) => {
        if (!axis.current) {
          if (Math.abs(xDir) > Math.abs(yDir)) axis.current = 'x';
          else if (Math.abs(yDir) > Math.abs(xDir)) axis.current = 'y';
        }

        if (down) ref.current.classList.add('is-dragging');
        else ref.current.classList.remove('is-dragging');

        if (
          down &&
          axis.current === 'x' &&
          distance > windowSize / (size.windowWidth <= 768 ? 6 : 5)
        )
          cancel(
            (index.current = clamp(
              index.current + (xDir > 0 ? -1 : 1),
              0,
              items.length - 1
            )),
            setSlide(index.current + 1),
            setInfo(items[index.current].alt)
          );

        // Save the slide index the drag started at
        if (first && down) {
          startingIndex.current = index.current;
        }

        setSize(() => {
          let w =
            down && axis.current === 'x' && startingIndex.current === 0
              ? (mx / window.innerWidth) * Math.abs(deltaX)
              : 0;
          let h =
            down && axis.current === 'x' && startingIndex.current === 0
              ? (mx / window.innerHeight) * Math.abs(deltaY)
              : 0;

          let mountGalleryRoute = 0;

          // If I've dragged for more than 1/3 of the screen width
          if (axis.current === 'x' && xDir > 0 && startingIndex.current === 0) {
            if (down && distance > window.innerWidth / 3 && xDir < 0) {
              // Preload the section and cancel the drag
              gatsbyLoader.hovering(parsePath(parentPath).pathname);
              cancel();
            }
            if (canceled) {
              w = window.innerWidth - size.containerWidth;
              h = window.innerHeight - size.containerHeight;
              mountGalleryRoute = 1;
              setTheme('light');
            }
          } else {
            w = 0;
            h = 0;
          }
          return { w, h, mountGalleryRoute, onRest: onDragXRest };
        });

        set(i => {
          if (i < index.current - 1 || i > index.current + 1)
            return { display: 'none' };

          const baseX = (i - index.current) * window.innerWidth;

          // Don't slide left if we are the the first slide
          const x =
            down && axis.current === 'x' && !(index.current === 0 && mx > 0)
              ? mx
              : 0;

          return { slideX: baseX + x, display: 'flex' };
        });

        if (last) axis.current = null;
      }
    );

    // Keyboard stuff

    useEffect(() => {
      if (rightArrow) {
        index.current = clamp(index.current + 1, 0, items.length - 1);
        setSlide(index.current + 1);
        setInfo(items[index.current].alt);
        set(i => {
          if (i < index.current - 1 || i > index.current + 1)
            return { display: 'none' };
          const baseX = (i - index.current) * window.innerWidth;
          return { slideX: baseX, display: 'flex' };
        });
      }
    }, [rightArrow]);

    useEffect(() => {
      if (leftArrow) {
        index.current = clamp(index.current - 1, 0, items.length - 1);
        setSlide(index.current + 1);
        setInfo(items[index.current].alt);
        set(i => {
          if (i < index.current - 1 || i > index.current + 1)
            return { display: 'none' };
          const baseX = (i - index.current) * window.innerWidth;
          return { slideX: baseX, display: 'flex' };
        });
      }
    }, [leftArrow]);

    if (index.current !== slide - 1) {
      index.current = slide - 1;
      set(i => {
        const wWidth = typeof window === 'undefined' ? 1000 : window.innerWidth;
        const baseX = (i - index.current) * wWidth;
        return { slideX: baseX, display: 'flex' };
      });
    }

    return props.map(({ slideX, display }, i) => (
      <Fragment key={i}>
        <animated.div
          {...bind()}
          key={i}
          style={{
            display,
            transform: slideX.interpolate(
              slideX => `translate3d(${slideX}px, 0, 0)`
            ),
          }}
          className="slide-wrapper"
        >
          {i === 0
            ? [
                <animated.div
                  key="dark-overlay"
                  className="dark-overlay"
                  style={{
                    backgroundColor: w.interpolate(
                      w =>
                        `rgba(0, 0, 0, ${
                          w > 0 ? 0 + clamp(Math.abs(w) / deltaX, 0, 0.4) : 0
                        })`
                    ),
                  }}
                />,
                <animated.div
                  key="arrow-container"
                  className="arrow-container"
                  {...bind()}
                  style={{
                    transform: w.interpolate(
                      w => `translate3d(${w > 0 ? w : 0}px, 0px, 0px)`
                    ),
                    opacity: w.interpolate(
                      w =>
                        `${w > 0 ? 1 - clamp(Math.abs(w) / deltaX, 0, 1) : 1}`
                    ),
                  }}
                >
                  <div className="arrow">
                    <Arrow
                      left
                      onClick={() => {
                        setTheme('light');
                        setSize(() => ({
                          w: window.innerWidth - size.containerWidth,
                          h: window.innerHeight - size.containerHeight,
                          mountGalleryRoute: 1,
                          onRest: onDragXRest,
                        }));
                      }}
                      onMouseEnter={() => {
                        gatsbyLoader.hovering(parsePath(parentPath).pathname);
                      }}
                    />
                  </div>
                </animated.div>,
              ]
            : null}
          <animated.div
            {...bind()}
            className="slide"
            style={
              i === 0
                ? {
                    width: w.interpolate(
                      w =>
                        `${
                          w > 0 ? size.containerWidth + w : size.containerWidth
                        }px`
                    ),
                    height: h.interpolate(
                      h =>
                        `${
                          h > 0
                            ? size.containerHeight + h
                            : size.containerHeight
                        }px`
                    ),
                    paddingTop: isMobileLandscape
                      ? 0
                      : h.interpolate(
                          h =>
                            `${PADDING.TOP -
                              clamp(Math.abs(h), 0, PADDING.TOP)}px`
                        ),
                    paddingBottom: isMobileLandscape
                      ? 0
                      : h.interpolate(
                          h =>
                            `${PADDING.BOTTOM -
                              clamp(Math.abs(h), 0, PADDING.BOTTOM)}px`
                        ),
                    paddingLeft: 0,
                    paddingRight: 0,
                    boxSizing: 'content-box',
                  }
                : // if removeBottomPadding is true, then we know for this gallery there's a description.
                // if there's a description, it will be in the second slide
                i === 1 && !isMobileLandscape
                ? {
                    paddingBottom: removeBottomPadding ? 0 : 20,
                  }
                : null
            }
          >
            {typeof items[i].img !== 'undefined' ? (
              i === 0 ? (
                typeof comingFrom === 'undefined' ? (
                  <Img
                    fixed={items[i].img}
                    draggable={false}
                    imgStyle={{
                      objectFit: 'cover',
                      pointerEvents: 'none',
                    }}
                    alt={items[i].alt}
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  />
                ) : (
                  <img
                    src={items[i].img}
                    draggable={false}
                    alt={items[i].alt}
                    style={{ objectFit: 'cover' }}
                    onDragStart={e => {
                      e.preventDefault();
                    }}
                  />
                )
              ) : (
                <Img
                  fixed={items[i].img}
                  draggable={false}
                  imgStyle={{
                    objectFit: 'contain',
                    pointerEvents: 'none',
                  }}
                  alt={items[i].alt}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              )
            ) : (
              typeof items[i].desc !== 'undefined' && (
                <div className="slide-description">
                  <Scrollbar momentum noScrollX maximalThumbSize={150}>
                    <h2>{items[i].title}</h2>
                    <div
                      dangerouslySetInnerHTML={{ __html: items[i].desc }}
                    ></div>
                  </Scrollbar>
                </div>
              )
            )}
          </animated.div>
        </animated.div>
      </Fragment>
    ));
  }
);

export default Slider;
