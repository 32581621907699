import { useState, useEffect } from 'react';

const useMediaQuery = query => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    setMatches(matchMedia(query).matches);
    const mediaQueryList = matchMedia(query);
    const onChange = e => {
      setMatches(e.matches);
    };
    mediaQueryList.addListener(onChange);
    return () => mediaQueryList.removeListener(onChange);
  }, [query]);

  return matches;
};

export default useMediaQuery;
