import { useState, useEffect } from 'react';
import debounce from '../utils/debounce';

const useScreenSize = () => {
  const [screenWidth, setScreenWidth] = useState(5000);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    handleResize();

    window.addEventListener(
      'resize',
      debounce(() => {
        handleResize;
      }, 250)
    );

    return () => window.removeEventListener('resize', handleResize);
  }, [screenWidth]);

  return screenWidth;
};

export default useScreenSize;
